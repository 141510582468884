import React from 'react';
import { Helmet } from 'react-helmet';

const DisableZoom = () => (
    <Helmet>
      <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
      />
    </Helmet>
);

export default DisableZoom;