import React from 'react';
import {motion} from "framer-motion";

const MotionButton = ({buttonText, buttonClass}) => {
    return (
        <div>
            <motion.button
                className={` btn btn-lg ${buttonClass} col-12`}
                whileHover={{scale: 1.10}}
                // whileTap={{scale: 1.10}}
            >
                {buttonText}
            </motion.button>

        </div>

    );
};

export default MotionButton;