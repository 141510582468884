// LibraryHeader.jsx

import React, { useEffect, useState } from 'react';
import CharacterAvatar from '../character/CharacterAvatar';
import apiService from '../../services/apiService';
import { UserAuth } from '../AuthContext';

const LibraryHeader = () => {
  const { user } = UserAuth();
  const [characterName, setCharacterName] = useState('');
  const [characterSettings, setCharacterSettings] = useState({
    skinTone: 'fair',
    hairStyle: 'buzzhair',
    hairColour: 'black',
  });

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const avatarData = await apiService.getAvatar(user.email);
        if (avatarData) {
          setCharacterName(avatarData.characterName || '');
          setCharacterSettings({
            skinTone: avatarData.skinTone || 'fair',
            hairStyle: avatarData.hairStyle || 'buzzhair',
            hairColour: avatarData.hairColour || 'black',
          });
        }
      } catch (error) {
        console.error('Error fetching avatar data:', error);
        // Handle the error, e.g., set default values or display an error message
      }
    };

    fetchAvatar();
  }, [user.email]);

  return (
    <div className="library-header-container bg-green-yellow-gradient small-rounded-top p-3 d-flex align-items-center">
      <h1 className="agbalumo-font text-navy-blue flex-grow-1 m-0">
        {characterName ? `${characterName}'s Library` : 'My Library'}
      </h1>
      <CharacterAvatar
          skinTone={characterSettings.skinTone}
          hairStyle={characterSettings.hairStyle}
          hairColour={characterSettings.hairColour}
          className="library-avatar"
          altText={`${characterName || 'User'}'s Avatar`}
          size={80}
      />

    </div>
  );
};

export default LibraryHeader;
