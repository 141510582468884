import React, {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import CharacterTrait from './CharacterTrait';

const IMAGE_BASE_URL = 'https://oststorage.blob.core.windows.net/ostpstrblb/app/';
const HAIR_STYLES_BASE_URL = `${IMAGE_BASE_URL}hair_styles/`;
const CHARACTER_IMAGE_BASE_URL = `${IMAGE_BASE_URL}v2_character_selector/`;

document.body.style.backgroundImage = `url("${IMAGE_BASE_URL}navy bg for GBCD.png")`;

const SKIN_TONES = [
    {value: 'fair', num: '1', img: 'fairskin.png', label: 'Fair Skin'},
    {value: 'golden', num: '2', img: 'goldenskin.png', label: 'Golden Skin'},
    {value: 'tan', num: '3', img: 'tanskin.png', label: 'Tan Skin'},
    {value: 'brown', num: '4', img: 'brownskin.png', label: 'Brown Skin'},
    {value: 'darkbrown', num: '5', img: 'darkbrownskin.png', label: 'Dark Brown Skin'},
];

const HAIR_STYLES = [
    {value: 'longhair', num: '6', img: 'long.png', label: 'Long Hair'},
    {value: 'pigtailshair', num: '7', img: 'pigtails.png', label: 'Pigtails Hair'},
    {value: 'waveyhair', num: '8', img: 'wavey.png', label: 'Wavey Hair'},
    {value: 'hijabhair', num: '9', img: 'hijab.png', label: 'Hijab Hair'},
    {value: 'braidshair', num: '10', img: 'braids.png', label: 'Braids Hair'},
    {value: 'buzzhair', num: '1', img: 'buzz.png', label: 'Buzz Hair'},
    {value: 'spikeyhair', num: '2', img: 'spikey.png', label: 'Spikey Hair'},
    {value: 'spikeyfadehair', num: '3', img: 'spikeyfade.png', label: 'Spikey Fade Hair'},
    {value: 'afrohair', num: '4', img: 'afro.png', label: 'Afro Hair'},
    {value: 'afrofadehair', num: '5', img: 'afrofade.png', label: 'Afro Fade Hair'},
];

const HAIR_COLOURS = [
    {value: 'black', num: '1', img: 'blackhair.png', label: 'Black Hair'},
    {value: 'darkbrown', num: '2', img: 'darkbrownhair.png', label: 'Dark Brown Hair'},
    {value: 'brown', num: '3', img: 'brownhair.png', label: 'Brown Hair'},
    {value: 'orange', num: '4', img: 'orangehair.png', label: 'Orange Hair'},
    {value: 'blonde', num: '5', img: 'blondehair.png', label: 'Blonde Hair'},
    {value: 'red', num: '6', img: 'redhair.png', label: 'Red Hair'},
];

const LANGUAGES = [
    {value: '1', label: 'English - Learn Arabic Words', disabled: false},
    {value: '2', label: 'English - Learn Spanish Words', disabled: true},
    {value: '3', label: 'English - Learn Somali Words', disabled: true},
    {value: '4', label: 'English - Learn Urdu Words', disabled: true},
    {value: '9', label: 'English - Learn Italian Words', disabled: true},
    {value: '5', label: 'English - Learn Japanese Words', disabled: true},
    {value: '6', label: 'English - Learn Turkish Words', disabled: true},
    {value: '7', label: 'English - Learn Bengali Words', disabled: true},
    {value: '8', label: 'English - Learn French Words', disabled: true},
];

const getNumByValue = (list, value) => list.find((item) => item.value === value)?.num || '1';

const CharacterSelector = ({
                               defaultCharacterSettings = {},
                               headerMessage,
                               textColour,
                               secondaryTextColour,
                               handleSubmit,
                               onCharacterSettingsChange,
                           }) => {

    const [characterName, setCharacterName] = useState(defaultCharacterSettings.characterName || '');
    const [skinTone, setSkinTone] = useState(defaultCharacterSettings.skinTone || 'fair');
    const [hairStyle, setHairStyle] = useState(defaultCharacterSettings.hairStyle || 'buzzhair');
    const [hairColour, setHairColour] = useState(defaultCharacterSettings.hairColour || 'black');
    const [demoCharacterImg, setDemoCharacterImg] = useState('');

    // Update state when defaultCharacterSettings changes
    useEffect(() => {
        setCharacterName(defaultCharacterSettings.characterName || '');
        setSkinTone(defaultCharacterSettings.skinTone || 'fair');
        setHairStyle(defaultCharacterSettings.hairStyle || 'buzzhair');
        setHairColour(defaultCharacterSettings.hairColour || 'black');
    }, [defaultCharacterSettings]);

    // Notify parent component of changes
    useEffect(() => {
        onCharacterSettingsChange({
            characterName,
            skinTone,
            hairStyle,
            hairColour,
        });
    }, [characterName, skinTone, hairStyle, hairColour, onCharacterSettingsChange]);

    // Update character image
    useEffect(() => {
        const skinToneNum = getNumByValue(SKIN_TONES, skinTone);
        const hairStyleNum = getNumByValue(HAIR_STYLES, hairStyle);
        const hairColourNum = getNumByValue(HAIR_COLOURS, hairColour);

        const newPath = `${hairStyleNum}_${skinToneNum}_${hairColourNum}.png`;
        setDemoCharacterImg(`${CHARACTER_IMAGE_BASE_URL}${newPath}`);
    }, [skinTone, hairStyle, hairColour]);

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit({characterName, skinTone, hairStyle, hairColour});
    };

    return (
        <div className="col-12 text-center mx-auto justify-content-between">

            <div className="col-12 mx-auto card bg-white border-white justify-content-between">
                <form onSubmit={onSubmit}>


                    {/* Select Skin Tone */}
                    <CharacterTrait
                        options={SKIN_TONES}
                        selectedValue={skinTone}
                        onChange={setSkinTone}
                        groupName="skinTone"
                        groupLabel="Select Skin Tone"
                        imageBaseUrl={IMAGE_BASE_URL}
                        textColour={textColour}
                        colWidth={"col-2"}
                    />

                    {/* Select Hair Style */}
                    <div className={"col-12"}>
                    <CharacterTrait
                        options={HAIR_STYLES}
                        selectedValue={hairStyle}
                        onChange={setHairStyle}
                        groupName="hairStyle"
                        groupLabel={"Select Hairstyle"}
                        imageBaseUrl={HAIR_STYLES_BASE_URL}
                        textColour={textColour}
                        colWidth={"custom-grid-col"}
                    />
                    </div>

                    {/* Select Hair Colour */}
                    <CharacterTrait
                        key={hairStyle}
                        options={HAIR_COLOURS}
                        selectedValue={hairColour}
                        onChange={setHairColour}
                        groupName="hairColour"
                        groupLabel={hairStyle === "hijabhair" ? "Select Hijab Colour" : "Select Hair Colour"}
                        imageBaseUrl={IMAGE_BASE_URL}
                        textColour={textColour}
                        colWidth={"col-2"}

                    />
                </form>

                {/* Character Display */}
                <div>
                    <img
                        src={demoCharacterImg}
                        alt="Your character"
                        className="col-10 col-md-8"
                        width="100%"
                        aria-live="polite"
                    />
                </div>

                <div className="col-12 mb-2">
                    <h3 className={`col-10 text-center mx-auto character-header mt-2 ${secondaryTextColour}`}>
                        {headerMessage}
                    </h3>
                    
                    <input
                        id="characterName"
                        className="col-8 mx-auto login-input"
                        type="text"
                        placeholder="ENTER YOUR CHARACTER'S NAME"
                        value={characterName}
                        onChange={(e) => setCharacterName(e.target.value)}
                        aria-label="Character Name"
                    />
                </div>

                <h3 className={`col-10 mx-auto login-header ${textColour}`}>Learn A Language</h3>
                <p className={textColour}>Choose a language other than English to learn</p>
                <div className="col-8 mx-auto text-center">
                    <Form.Select
                        aria-label="Second language selection"
                        className="text-center login-input mx-auto"
                    >
                        {LANGUAGES.map((lang) => (
                            <option key={lang.value} value={lang.value} disabled={lang.disabled}>
                                {lang.label}
                            </option>
                        ))}
                    </Form.Select>
                </div>
            </div>
        </div>
    );
};

CharacterSelector.propTypes = {
    defaultCharacterSettings: PropTypes.object,
    headerMessage: PropTypes.string.isRequired,
    textColour: PropTypes.string,
    secondaryTextColour: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    onCharacterSettingsChange: PropTypes.func.isRequired,
};

CharacterSelector.defaultProps = {
    defaultCharacterSettings: {},
    textColour: '',
    secondaryTextColour: '',
};

export default CharacterSelector;
