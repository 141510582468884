import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserAuth } from '../components/AuthContext';
import CharacterSelector from '../components/character/CharacterSelector';
import TrialSignup from '../components/TrialSignup';
import ScrollToTop from '../components/ScrollToTop';
import apiService from '../services/apiService';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style/cardslider.css';
import { motion, useScroll } from 'framer-motion';

const CheckoutTrial = () => {
    const navigate = useNavigate();
    const { createUser, signIn } = UserAuth();
    const { scrollYProgress } = useScroll();

    const [userCredentials, setUserCredentials] = useState({
        email: '',
        password: '',
    });

    const [characterSettings, setCharacterSettings] = useState({
        characterName: '',
        hairColour: 'black',
        skinTone: 'fair',
        hairStyle: 'buzzhair',
    });

    const [isLoading, setIsLoading] = useState(false);
    const priceLink = process.env.REACT_APP_BIRTHDAY_BOOK;

    // Helper Functions
    const displayErrorMessage = (message) => {
        toast.error(message);
        setIsLoading(false);
    };

    const validateInput = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const { email, password } = userCredentials;

        if (characterSettings.characterName.trim().length < 2) {
            displayErrorMessage("Your hero's name needs to be 2 characters or longer");
            return false;
        }
        if (!emailRegex.test(email) || email.trim().length < 5) {
            displayErrorMessage('Your email is in the incorrect format');
            return false;
        }
        if (password.length < 8) {
            displayErrorMessage('Passwords must be at least 8 characters long.');
            return false;
        }
        return true;
    };

    const updateCharacterSettings = async (email) => {
        const settings = {
            ...apiService.getDefaultCharacterSettings(),
            characterName: characterSettings.characterName,
            hairColour: characterSettings.hairColour,
            skinTone: characterSettings.skinTone,
            hairStyle: characterSettings.hairStyle,
            email,
        };

        try {
            await apiService.updateAvatar(settings);
        } catch (error) {
            displayErrorMessage('Error updating character settings.');
        }
    };

    // User Provisioning
    const provisionUserWithCredentials = async () => {
        setIsLoading(true);
        try {
            await createUser(userCredentials.email, userCredentials.password);
            await updateCharacterSettings(userCredentials.email);
            await signIn(userCredentials.email, userCredentials.password);
            navigateToPostSale();
        } catch (error) {
            displayErrorMessage('An error occurred while creating your account. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const provisionUserWithSso = async (result) => {
        setIsLoading(true);
        try {
            await updateCharacterSettings(result.email);
            navigateToPostSale();
        } catch (error) {
            displayErrorMessage('An error occurred during single sign-on. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const navigateToPostSale = () => {
        navigate(`/post-sale-trial-signed-in?sessionid=${priceLink}`);
    };

    // Handlers
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateInput()) {
            await provisionUserWithCredentials();
        }
    };

    const handleSsoAuthSuccess = (result) => {
        provisionUserWithSso(result);
    };

    const handleSsoAuthFail = () => {
        displayErrorMessage('Single sign-on authentication failed unexpectedly.');
    };

    const handleSsoAuthClick = () => {
        return characterSettings.characterName.trim().length >= 2;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserCredentials((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <>
            <motion.div className="progress-bar" style={{ scaleX: scrollYProgress }} />
            <div className="col-12 col-md-6 mx-auto align-items-center">
                <ScrollToTop />
                <div className="col-10 mx-auto card bg-white border-white align-items-center d-flex">
                    <CharacterSelector
                        defaultCharacterSettings={characterSettings}
                        onCharacterSettingsChange={setCharacterSettings}
                        headerMessage="Your Character's Name"
                        textColour="text-navy"
                    />

                    <TrialSignup
                        email={userCredentials.email}
                        setEmail={(email) => setUserCredentials({ ...userCredentials, email })}
                        password={userCredentials.password}
                        setPassword={(password) => setUserCredentials({ ...userCredentials, password })}
                        headerMessage="Register"
                        onSsoAuthSuccess={handleSsoAuthSuccess}
                        onSsoAuthFail={handleSsoAuthFail}
                        onSsoAuthClick={handleSsoAuthClick}
                    />

                    <div className="col-12 mx-auto text-center">
                        <motion.button
                            className={`col-6 oswald-font btn btn-lg ${isLoading ? 'btn-warning' : 'btn-primary'} text-bold rounded-3 rounded-pill mb-3`}
                            onClick={handleSubmit}
                            disabled={isLoading}
                            whileHover={{ scale: 1.10 }}
                            whileTap={{ scale: 1.10 }}
                        >
                            {isLoading ? 'LOADING ...' : 'START READING'}
                        </motion.button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CheckoutTrial;
