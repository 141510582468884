// src/services/apiService.js

const API_BASE_URL = process.env.REACT_APP_B_KEND;

class ApiService {
    constructor(baseURL) {
        this.baseURL = baseURL;
    }

    getDefaultCharacterSettings() {
        return {
            email: '',
            firstName: '',
            lastName: '',
            characterName: '',
            skinTone: 'fair',
            hairStyle: 'buzzhair',
            hairColour: 'black',
            bookSelected: '',
            bookMessage: '',
        };
    }

    async handleResponse(response) {
        if (!response.ok) {
            const errorData = await response.json();
            const errorMessage =
                errorData.message || 'An error occurred while processing your request.';
            const error = new Error(errorMessage);
            error.response = errorData;
            throw error;
        }
        return response.json();
    }

    // Method to update the user's character
    async updateUsersCharacter(characterSettings) {
        const response = await fetch(`${this.baseURL}/osthardcoveruser`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(characterSettings),
        });
        return this.handleResponse(response);
    }

    // Method to get an avatar by email
    async getAvatar(email) {
        const response = await fetch(`${this.baseURL}/avatar?email=${encodeURIComponent(email)}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return this.handleResponse(response);
    }

    // Method to update an avatar
    async updateAvatar(avatarSettings) {

        const response = await fetch(`${this.baseURL}/avatar`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(avatarSettings),

        });
        
        return this.handleResponse(response);
    }
}

const apiService = new ApiService(API_BASE_URL);

export default apiService;
