import React from 'react';

const BookCoverTextFormatter = ({ overlayText }) => {
    const wordsArray = overlayText.split(' ');

    let words;

    if (wordsArray.length === 4) {
        words = [];

        // First word on a new line
        words.push(
            <span key={0}>
        {wordsArray[0]}
                <br />
      </span>
        );

        // Second and third words on the same line
        words.push(
            <span key={1}>
        {wordsArray[1]} {wordsArray[2]}
                <br />
      </span>
        );

        // Fourth word on a new line
        words.push(
            <span key={2}>
        {wordsArray[3]}
                <br />
      </span>
        );
    } else {
        words = wordsArray.map((word, index) => (
            <span key={index}>
        {word}
                <br />
      </span>
        ));
    }

    return <div>{words}</div>;
};

export default BookCoverTextFormatter;
