// CharacterAvatar.jsx

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './CharacterAvatar.css';
import {Link} from "react-router-dom";

const CHARACTER_IMAGE_BASE_URL = 'https://oststorage.blob.core.windows.net/ostpstrblb/app/v2_character_selector/';

const SKIN_TONES = [
  {value: 'fair', num: '1', img: 'fairskin.png', label: 'Fair Skin'},
  {value: 'golden', num: '2', img: 'goldenskin.png', label: 'Golden Skin'},
  {value: 'tan', num: '3', img: 'tanskin.png', label: 'Tan Skin'},
  {value: 'brown', num: '4', img: 'brownskin.png', label: 'Brown Skin'},
  {value: 'darkbrown', num: '5', img: 'darkbrownskin.png', label: 'Dark Brown Skin'},
];

const HAIR_STYLES = [
  {value: 'longhair', num: '6', img: 'long.png', label: 'Long Hair'},
  {value: 'pigtailshair', num: '7', img: 'pigtails.png', label: 'Pigtails Hair'},
  {value: 'waveyhair', num: '8', img: 'wavey.png', label: 'Wavey Hair'},
  {value: 'hijabhair', num: '9', img: 'hijab.png', label: 'Hijab Hair'},
  {value: 'braidshair', num: '10', img: 'braids.png', label: 'Braids Hair'},
  {value: 'buzzhair', num: '1', img: 'buzz.png', label: 'Buzz Hair'},
  {value: 'spikeyhair', num: '2', img: 'spikey.png', label: 'Spikey Hair'},
  {value: 'spikeyfadehair', num: '3', img: 'spikeyfade.png', label: 'Spikey Fade Hair'},
  {value: 'afrohair', num: '4', img: 'afro.png', label: 'Afro Hair'},
  {value: 'afrofadehair', num: '5', img: 'afrofade.png', label: 'Afro Fade Hair'},
];

const HAIR_COLOURS = [
  {value: 'black', num: '1', img: 'blackhair.png', label: 'Black Hair'},
  {value: 'darkbrown', num: '2', img: 'darkbrownhair.png', label: 'Dark Brown Hair'},
  {value: 'brown', num: '3', img: 'brownhair.png', label: 'Brown Hair'},
  {value: 'orange', num: '4', img: 'orangehair.png', label: 'Orange Hair'},
  {value: 'blonde', num: '5', img: 'blondehair.png', label: 'Blonde Hair'},
  {value: 'red', num: '6', img: 'redhair.png', label: 'Red Hair'},
];


const getNumByValue = (list, value) => list.find((item) => item.value === value)?.num || '1';

const CharacterAvatar = ({
  skinTone,
  hairStyle,
  hairColour,
  className,
  altText = 'Character Avatar',
  size = 50, // Size in pixels
}) => {
  const [avatarImgSrc, setAvatarImgSrc] = useState('');

  useEffect(() => {
    const skinToneNum = getNumByValue(SKIN_TONES, skinTone);
    const hairStyleNum = getNumByValue(HAIR_STYLES, hairStyle);
    const hairColourNum = getNumByValue(HAIR_COLOURS, hairColour);

    const avatarImageName = `${hairStyleNum}_${skinToneNum}_${hairColourNum}.png`;
    const avatarImagePath = `${CHARACTER_IMAGE_BASE_URL}${avatarImageName}`;
    setAvatarImgSrc(avatarImagePath);
  }, [skinTone, hairStyle, hairColour]);

  return (
    
    <div className='character-avatar-container'>
      <div
      className={`avatar-container ${className}`}
      style={{
        width: size,
        height: size,
      }}
    >
      <Link to={"/store-character"}
      >
      <img  
        src={avatarImgSrc}
        alt={altText}
        className="avatar-image"
      />
      </Link>
    </div>
    <Link to={"/store-character"}>
    <a className="avatar-label">EDIT</a>
    </Link>
    </div>
  );
};

CharacterAvatar.propTypes = {
  skinTone: PropTypes.string.isRequired,
  hairStyle: PropTypes.string.isRequired,
  hairColour: PropTypes.string.isRequired,
  className: PropTypes.string,
  altText: PropTypes.string,
  size: PropTypes.number,
};

CharacterAvatar.defaultProps = {
  className: '',
  altText: 'Character Avatar',
  size: 50,
};

export default CharacterAvatar;
