import React from 'react';
import {Link, useNavigate} from 'react-router-dom';


const EndBookSalesButton = ({bookName}) => {
    const navigate = useNavigate();


    const salesRedirect = async () => {
        // if eid book go to stripe
        if(bookName == "eid") {
            window.open('https://buy.stripe.com/bIY2au6Pa2VPet25kP', '_blank');
        } else
        {
            navigate('/buy-hard-cover-book-form');
        }
        
    };


    return (
        <button
            className={"col-4 btn glowing-button mx-auto text-center"}
            onClick={salesRedirect}
            style={{ position: 'absolute', top: '80%', right: '33%', zIndex: '1000' }}
        >
            <span >BUY NOW</span>
        </button>

    );
};

export default EndBookSalesButton;