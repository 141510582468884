import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../style/NavMenu.css'
import { auth } from "./firebase";

export class NavMenu extends Component {
  static displayName = NavMenu.name;


  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      isLoggedIn: false
    };

    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  componentDidMount() {
    this.authListener = auth.onAuthStateChanged((user) => {
      this.setState({ isLoggedIn: !!user });
    });
  }

  componentWillUnmount() {
    this.authListener(); // Unsubscribe from the listener when the component unmounts
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  logout = () => {
    auth.signOut()
      .then(() => {
        this.setState({ isLoggedIn: false });
      })
      .catch((error) => {
        console.error('Sign Out Error', error);
      });
  }

  render() {
    return (
      <header className={"header-custom"}>
        <Navbar className="navbar-custom base-color navbar-dark navbar-expand-lg navbar-toggleable-sm ng-white box-shadow mb-3" container light>
          <NavbarBrand className="brand-text" tag={Link} to={this.state.isLoggedIn ? "/signed-in" : "/"}>
            <img className="brand-image" src="/images/ost_owl_logo.png" alt={"logo"} />
          </NavbarBrand>

          <NavbarToggler onClick={this.toggleNavbar} className="  mr-2" />
          <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-dropdown navbar-nav flex-grow">
              <NavItem className="shapes" >
                <NavLink tag={Link} className="text-dark" to={this.state.isLoggedIn ? "/signed-in" : "/"} onClick={this.toggleNavbar}>HOME</NavLink>
              </NavItem>
              <NavItem>
                {
                  !this.state.isLoggedIn &&
                  <NavLink tag={Link} className="text-dark" to="/checkout-trial" onClick={this.toggleNavbar}>CREATE A STORY</NavLink>
                }
              </NavItem>
              {/*<NavItem>*/}
              {/*  <NavLink tag={Link} className="text-dark" to="/buy-now" onClick={this.toggleNavbar}>BUY</NavLink>*/}
              {/*</NavItem>*/}
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/faq" onClick={this.toggleNavbar}>FAQ</NavLink>
              </NavItem>
              <NavItem>
                {this.state.isLoggedIn ? (
                  <NavLink tag={Link} className="text-dark" to="/" onClick={this.logout}>SIGN OUT</NavLink>
                ) : (
                  <NavLink tag={Link} className="text-dark" to="/login" onClick={this.toggleNavbar}>SIGN IN</NavLink>
                )}
              </NavItem>

            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
