import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import './custom.css';
import './style/main.css'
import './style/NavMenu.css'
import './style/BookReader.css'
import './style/home.css'
import { ToastContainer } from 'react-toastify';
import { AuthContextProvider } from "./components/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import StoreCharacter from "./components/StoreCharacter";
import PostSaleSignedIn from "./components/PostSaleSignedIn";
import ExistingPostSaleSignedIn from "./pages/ExistingPostSaleSignedIn";
import BookReader from "./pages/BookReader";
import SignedIn from "./pages/SignedIn";
import Footer from './components/Footer';

export default class App extends Component {
    static displayName = App.name;
    render() {
    return (
        <div>
            <AuthContextProvider>
                <Layout />
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                    <Route path={'signed-in'} element={
                        <ProtectedRoute>
                            <SignedIn />
                        </ProtectedRoute>
                    } />
                    <Route path={'store-character'} element={
                        <ProtectedRoute>
                            <StoreCharacter />
                        </ProtectedRoute>
                    } />
                    <Route path={'post-sale-signed-in'} element={
                        <ProtectedRoute>
                            <PostSaleSignedIn />
                        </ProtectedRoute>
                    } />
                    <Route path={'existing-post-sale-signed-in'} element={
                        <ProtectedRoute>
                            <ExistingPostSaleSignedIn />
                        </ProtectedRoute>
                    } />
                    <Route path={'logged-in-book-reader'} element={
                        <ProtectedRoute>
                            <BookReader />
                        </ProtectedRoute>
                    } />
                    <Route path={'post-sale-book-slider'} element={
                        <ProtectedRoute>
                            <BookReader />
                        </ProtectedRoute>
                    } />
                </Routes>
                <Footer/>
            </AuthContextProvider>
            <ToastContainer
                className="toast-position"
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark" />
        </div>
    );
    }
}