import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { useLocation, Link } from 'react-router-dom';

const Layout = ({ children }) => {
    const location = useLocation();

    const hideNavMenu = location.pathname === '/book-reader';

    return (
        <div>
            {/* Temporarily removed top banner */}
            {/* <div className="col-12 countdown-timer"><p><Link style={{ textDecoration: 'none' }} to={"/buy-now"} className={"text-navy"}>WELCOME TO OUR TESTING PROGRAM</Link></p></div> */}
            
            {!hideNavMenu && <NavMenu />} {/* Conditionally render the NavMenu */}
            
            <Container>
                {children}
            </Container>
        </div>
    );
};

export default Layout;
