
const REVIEW_ONE_REVIEWER_DETAILS = "Mariam, Carlton Vic";
const REVIEW_ONE_DESCRIPTION = "As a parent I definitely love the concept. It's user friendly. I love that kid can use it without my help..";

const REVIEW_TWO_REVIEWER_DETAILS = "Sophie, North Melbourne Vic";
const REVIEW_TWO_DESCRIPTION = "Love this, we got one too.";

const REVIEW_THREE_REVIEWER_DETAILS = "Omar, Truganina Vic";
const REVIEW_THREE_DESCRIPTION = "Before using Our Storytime my son never used to read. Now he can't get enough.";

const REVIEW_FOUR_REVIEWER_DETAILS = "Sunny, Sunshine Vic";
const REVIEW_FOUR_DESCRIPTION = "The kids absolutely love this";
const REVIEW_FIVE_REVIEWER_DETAILS = "Hannah, Brunswick Vic";
const REVIEW_FIVE_DESCRIPTION = "Lovely stories for putting the kids to bed!";

export {REVIEW_ONE_REVIEWER_DETAILS,REVIEW_ONE_DESCRIPTION, 
    REVIEW_TWO_REVIEWER_DETAILS, REVIEW_TWO_DESCRIPTION,
    REVIEW_THREE_REVIEWER_DETAILS, REVIEW_THREE_DESCRIPTION,
    REVIEW_FOUR_REVIEWER_DETAILS, REVIEW_FOUR_DESCRIPTION,
    REVIEW_FIVE_REVIEWER_DETAILS, REVIEW_FIVE_DESCRIPTION
}

const rootImageFolder = "https://oststorage.blob.core.windows.net/ostpstrblb/app/website_feature_images/"



//Latest Reviews
const  USER_REVIEWS = [
    {
        text: "'The custom character's look amazing and the stories are great'",
        image: rootImageFolder + "reviewer_1.png",
        rating: 5,
        name: "Sam"
    },
    {
        text: "'As a parent I definitely love the concept. I love that my kids can use it without my help'",
        image: rootImageFolder + "reviewer_2.png",
        rating: 5,
        name: "Amina"

    },
    {
        text: "'Love the idea, there is a real need for this.'",
        image: rootImageFolder + "reviewer_3.png",
        rating: 5,
        name: "Jimmy"

    },

    {
        text: "'My son loved seeing himself in the books'",
        image: rootImageFolder + "reviewer_4.png",
        rating: 5,
        name: "Omar"
    },
    {
        text: "'Love this! We got a printed book too'",
        image: rootImageFolder + "reviewer_5.png",
        rating: 5,
        name: "Sara"
    },
    {
        text: "'My daughter loved it. It was exciting for her to be incorporated in the storyline'",
        image: rootImageFolder + "reviewer_6.png",
        rating: 4,
        name: "Meena"

    }
];

export {USER_REVIEWS}
