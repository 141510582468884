import React, { useEffect, useRef } from 'react';
import Confetti from 'react-confetti';

const BookConfetti = (soundActive = false) => {
    const { innerWidth: width, innerHeight: height } = window;
    
    // Ref for the audio element
    const audioRef = useRef(null);

    useEffect(() => {
        // Delay the playback by 3 seconds (3000ms)
        const playAudioWithDelay = setTimeout(() => {
            if (audioRef.current) {
                audioRef.current.play();
            }
        }, 1000); // 3000ms = 3 seconds

        // Cleanup the timeout if the component is unmounted
        return () => clearTimeout(playAudioWithDelay);
    }, []);

    return (
        <>
        <Confetti
            width={width}
            height={height}
            numberOfPieces={1000}
            recycle={false}
            gravity={0.08}
        />
            {/* Audio element with MP3 file */}
            {soundActive && (
                <audio ref={audioRef} src="/sound/confetti.mp3" type="audio/mpeg" />
            )}        </>
    );
};

export default BookConfetti;
