import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import default CircularProgressbar styles
import './ProgressBar.css'; // Import your custom styles

const ProgressBar = ({ currentPage, totalPages }) => {
    return (
        <span className="progress-bar-container">
            <CircularProgressbar
                value={currentPage}
                maxValue={totalPages}
                text={`${currentPage}/${totalPages}`}
                strokeWidth={15}
                styles={{
                    path: { stroke: '#4CB1B5' },
                    trail: { stroke: 'grey' },
                    background: { fill: '#fff' },
                    text: { fill: '#4CB1B5' },
                }}
                backgroundPadding={20}
            />
        </span>
    );
};

export default ProgressBar;
