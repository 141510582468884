import React, { useState, useCallback } from 'react';
import Fullpage, { FullPageSections } from '@ap.cx/react-fullpage';
import HomeButton from './HomeButton';
import ProgressBar from './ProgressBar';
import BookCarousel from './BookCarousel';
import TurnDevicePopUp from './TurnDevicePopUp';
import BookConfetti from './BookConfetti';
import useImagePreloader from '../../hooks/useImagePreloader';
import EndBookSalesButton from "./EndBookSalesButton";
import DisableZoom from "./DisableZoom";

const Reader = ({ book }) => {
    const bookLength = book.pageList.length;
    const { fontFamily } = book;

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const loadedImages = useImagePreloader(book.pageList, currentPageNumber);
    
    const handlePageTurn = useCallback((selectedIndex) => {
        setCurrentPageNumber(selectedIndex + 1);
    }, []);

    return (
        <Fullpage>
            <DisableZoom />
            <FullPageSections>
                {/* Confetti on second last page */}
                {currentPageNumber === bookLength-1 && <BookConfetti soundActive={true} />}

                {/* Device orientation prompt on the first page */}
                {currentPageNumber === 1 && <TurnDevicePopUp />}

                {/* Home button */}
                <HomeButton />
                
                
                {/* Sales Prompt */}
                {currentPageNumber === bookLength && <EndBookSalesButton
                    bookName={book.bookName}
                />}

                {/* Progress bar */}
                <ProgressBar currentPage={currentPageNumber} totalPages={bookLength} />

                {/* Book carousel */}
                <BookCarousel
                    pages={book.pageList}
                    onPageChange={handlePageTurn}
                    currentPage={currentPageNumber}
                    fontFamily={fontFamily}
                    loadedImages={loadedImages}
                />
            </FullPageSections>
        </Fullpage>
    );
};

export default Reader;
