import React, { useState } from 'react';
import useSound from 'use-sound';

const SoundButton = ({ soundFile }) => {
    const [isPlaying, setIsPlaying] = useState(false); // Custom state to track if the sound is playing
    const [play] = useSound(soundFile, {
        format: ['m4a'],
        onend: () => setIsPlaying(false), // Reset isPlaying when the sound finishes
    });

    const handleButtonClick = () => {
        if (!isPlaying) {
            setIsPlaying(true);
            play();
        }
    };

    return (
        <div>
            <button
                className="bg-transparent"
                onClick={handleButtonClick}
                disabled={isPlaying} // Disable the button while sound is playing
            >
                <img
                    src="https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/sound_icon.png"
                    alt="Sound Button"
                    style={{ width: '50px', height: '50px' }}
                    className="pulsating"
                />
            </button>
        </div>
    );
};

export default SoundButton;
