import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from './AuthContext';
import CharacterSelector from './character/CharacterSelector';
import ScrollToTop from './ScrollToTop';
import apiService from '../services/apiService';

const OSTBOOKSTORAGE_BASE_URL = 'https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/';


const StoreCharacter = () => {
    useEffect(() => {
        document.body.style.backgroundImage =
            `url("${OSTBOOKSTORAGE_BASE_URL}navy bg for GBCD.png")`;
        return () => {
            document.body.style.backgroundImage = '';
        };
    }, []);

    const { user } = UserAuth();
    const navigate = useNavigate();

    const [characterSettings, setCharacterSettings] = useState({
        hairColour: 'black',
        skinTone: 'fair',
        hairStyle: 'buzzhair',
        characterName: '',
    });

    const email = user.email;

    useEffect(() => {
        const fetchAvatar = async () => {
            try {
                const avatarData = await apiService.getAvatar(email);
                if (avatarData) {
                    setCharacterSettings({
                        hairColour: avatarData.hairColour,
                        skinTone: avatarData.skinTone,
                        hairStyle: avatarData.hairStyle,
                        characterName: avatarData.characterName,
                    });
                }
            } catch (err) {
                console.error('Error fetching avatar:', err);
            }
        };
        fetchAvatar();
    }, [email]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (characterSettings.characterName.trim() !== '') {
            const settings = {
                email: user.email,
                ...characterSettings,
            };

            try {
                await apiService.updateAvatar(settings);
                navigate('/signed-in');
            } catch (err) {
                console.error('Error updating avatar:', err);
            }
        } else {
            alert('Please enter a character name.');
        }
    };

    return (
        <div className="col-12 col-md-6 mx-auto align-items-center">
            <ScrollToTop />
            <div className="col-10 mx-auto card bg-white border-white align-items-center justify-content-center">
                <CharacterSelector
                    defaultCharacterSettings={characterSettings}
                    headerMessage="Update Character"
                    textColour="text-navy"
                    secondaryTextColour="text-navy"
                    handleSubmit={handleSubmit}
                    onCharacterSettingsChange={setCharacterSettings}
                />
                <button
                    className="col-md-3 col-6 btn btn-lg secondary-button"
                    onClick={handleSubmit}
                >
                    SAVE
                </button>
            </div>
        </div>
    );
};

export default StoreCharacter;
