import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import CharacterSelector from "../components/character/CharacterSelector";
import {usePageViewTracker, useEventTracker} from "../services/TelemetryHooks";


const CharacterSelectorTryDemo = () => {
    usePageViewTracker('Try Now');
    const trackEvent = useEventTracker();


    document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';

    const [hairColour, setHairColour] = useState("black");
    const [skinTone, setSkinTone] = useState("fair");
    const [hairStyle, setHairStyle] = useState("buzzhair");
    const [characterName, setCharacterName] = useState("");
    const [promoCode, setPromoCode] = useState("");
    const data = {hairColour, skinTone, hairStyle, characterName, promoCode}
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    function handleSubmit(event) {

        trackEvent('Configured Character', {
            hairColour: hairColour,
            skinTone: skinTone,
            hairStyle: hairStyle,
            characterName: characterName
        });

        if (characterName.length > 2) {
            navigate('/trial-book-reader', {state: data});
            event.preventDefault();
        } else {
            setErrorMessage("Please Enter Your Child's Name");
        }
    }

    return (
        <div>
        <div className={'card col-11 bg-white mx-auto bg-white border-white'}>
            <CharacterSelector
                hairColour={hairColour}
                setHairColour={setHairColour}
                skinTone={skinTone}
                setSkinTone={setSkinTone}
                hairStyle={hairStyle}
                setHairStyle={setHairStyle}
                characterName={characterName}
                setCharacterName={setCharacterName}
                headerMessage={"Try Demo"}
                textColour={"text-navy-blue"}
                handleSubmit={handleSubmit}
                secondaryTextColour={"text-navy-blue"}
            />

            <div className={"text-center mx-auto"}>
                <h4 className={'text-navy-blue'}>Enter Promo Code</h4>
                <div className={"char-name"}>
                    <input className="col-10 col-md-10 text-input" type="input" id="input" name="input"
                           placeholder=" ENTER PROMO CODE" value={promoCode}
                           onChange={(e) => setPromoCode(e.target.value)}/>
                </div>
            </div>

        </div>
            <div><h3 className={"text-danger text-center"}>{errorMessage}</h3></div>
            <div className={"button-container"}>
                <button className="col-md-3 col-6 btn btn-lg secondary-button" onClick={handleSubmit}>
                    READ BOOK
                </button>
            </div>
    
        </div>
    );
};
export default CharacterSelectorTryDemo;
            