import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ImageWithText from './ImageWithText';
const BookCarousel = ({ pages, onPageChange, currentPage, fontFamily, loadedImages }) => (
    <Carousel
        interval={null}
        indicators={true}
        wrap={false}
        onSelect={onPageChange}
        className="full-screen-carousel"
    >
        {pages.map((page, index) => (
            <Carousel.Item key={index}>
                {loadedImages[index] ? (
                    <ImageWithText
                        imageUrl={page.imageUrl}
                        text={page.pageText.replace(/%20/g, ' ')}
                        pos={page.pageTextConfiguration}
                        fontFamily={fontFamily}
                        textMetadata={page.pageTextConfiguration}
                    />
                ) : (
                    <div className="loading-placeholder">Loading...</div>
                )}
            </Carousel.Item>
        ))}
    </Carousel>
);

export default BookCarousel;
