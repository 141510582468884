import React from 'react';
import {usePageViewTracker} from "../../services/TelemetryHooks";
import Accordion from 'react-bootstrap/Accordion';


function Faq() {
    usePageViewTracker('FAQ');


    document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';

    return (
        <div>
            <div className={"col-10 mx-auto justify-content-center align-items-center bg-sand p-2  rounded-lg"}
                 style={{borderRadius: '30px'}}>
                <h1 className={"text-center mb-5 text-navy-blue"}>Frequently Asked Questions</h1>


                <Accordion defaultActiveKey="0" flush className={"rounded-lg "}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="custom-accordion ">1. Is the Our Storytime Online Web Platform Free?</Accordion.Header>
                        <Accordion.Body>
                            Getting started on our online web platform is free. Your personalised digital stories can be accessed on web browsers across mobile, tablet and desktop devices so you can read whether at home or on the go.
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className="custom-accordion">2. Can I order a hard copy?</Accordion.Header>
                        <Accordion.Body>
                            Yes you can! Once you login to your library you'll have access to your personal bookshop where you can purchase physical copies and have your stories professionally printed and delivered.
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>3. How old does my child need to be?</Accordion.Header>
                        <Accordion.Body>
                            Our Storytime books are designed for children aged 2 - 8. But that doesn’t mean older children have to miss out on the fun! </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="3">
                        <Accordion.Header>4. How do I contact Our Storytime?</Accordion.Header>
                        <Accordion.Body>
                            The best way to reach us is via email at sales@ourstorytime.com.
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                        <Accordion.Header>5. How often does Our Storytime release books?</Accordion.Header>
                        <Accordion.Body>
                            We typically release 1-2 new stories at a time. </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="5">
                        <Accordion.Header>6. My child doesn’t fit within the customisation options?</Accordion.Header>
                        <Accordion.Body>
                            We are always working on providing new customisation options, if you don’t see a suitable
                            option for your child feel free to email suggestions to sales@ourstorytime.com and we’ll do
                            our very best to accommodate in future releases. In the meantime, feel free to enjoy our
                            current catalogue of options and let their imagination run wild. </Accordion.Body>
                    </Accordion.Item>
                    
                </Accordion>

                <div className={"mb-5"}></div>

            </div>
            <div className={"mb-5"}></div>



        </div>
    )
        ;
}

export default Faq;
