import { useEffect, useState } from 'react';

const useImagePreloader = (pages, currentPage) => {
    const [loadedImages, setLoadedImages] = useState({});

    useEffect(() => {
        const preloadImage = (index) => {
            if (index >= 0 && index < pages.length && !loadedImages[index]) {
                const img = new Image();
                img.src = pages[index].imageUrl;
                img.onload = () => {
                    setLoadedImages((prev) => ({
                        ...prev,
                        [index]: true,
                    }));
                };
            }
        };

        // Preload the current page and the next page for smoother transitions
        preloadImage(currentPage - 1);
        preloadImage(currentPage);
        preloadImage(currentPage - 2); // Optionally preload the previous page

    }, [currentPage, pages, loadedImages]);

    return loadedImages;
};

export default useImagePreloader;
