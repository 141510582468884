import React from 'react';
import SingleSignOn from './authentication/SingleSignOn';


const TrialSignup = ({
                         email,
                         setEmail,
                         password,
                         setPassword,
                         onSsoAuthSuccess = {},
                         onSsoAuthFail = {},
                         onSsoAuthClick = {}
                     }) => {

    return (
        <div className={"col-12 mx-auto bg-white text-center"}>
            <table className={"col-12 mx-auto"}>
                <thead>
                <tr>
                    <th>
                        <div>
                            <div>
                                <h3 className={"col-10 mx-auto login-header negative-20-margin-top"}>Create Free
                                    Account</h3>
                            </div>

                            <SingleSignOn
                                onClick={onSsoAuthClick}
                                onSuccess={onSsoAuthSuccess}
                                onFail={onSsoAuthFail}
                            />
                            <p className='col-2 login-header mx-auto oswald-font text-dark '>OR</p>
                            <form className={"col-10 mx-auto"}>
                                <div className={"mx-auto"}>
                                    <div>
                                        <div>
                                            <label className={"mx-auto login-text"} htmlFor="email-address">
                                                EMAIL ADDRESS
                                            </label>
                                        </div>
                                        <input
                                            className={"mx-auto login-input col-10 negative-20-margin-top"}
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            placeholder="ENTER EMAIL ADDRESS"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <label className={"login-text"} htmlFor="password">
                                            CREATE PASSWORD
                                        </label>
                                    </div>

                                    <input
                                        className={"login-input col-10 negative-20-margin-top"}
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        placeholder="ENTER PASSWORD"
                                    />
                                </div>


                                <div>
                                    <p className={"small-text"}> By proceeding you are agreeing to our <a
                                        href="/privacy-policy" target="_blank"
                                        rel="noopener noreferrer">
                                        privacy policy
                                    </a> and our <a
                                        href="/terms-of-service" target="_blank"
                                        rel="noopener noreferrer">
                                        terms of service
                                    </a>

                                    </p>
                                </div>
                            </form>
                        </div>
                    </th>
                </tr>
                </thead>
            </table>
        </div>
    );
}

export default TrialSignup;