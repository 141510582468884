// src/components/TurnDevicePopUp.jsx

import React, { useEffect, useRef } from 'react';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';
import './TurnDevicePopUp.css'; // Ensure this CSS file exists and is properly styled

// Initialize SweetAlert2 with React content
const MySwal = withReactContent(Swal);

const TurnDevicePopUp = () => {
    // Ref to track if the popup is currently shown
    const isPopupOpenRef = useRef(false);

    useEffect(() => {
        // Function to determine if the device is in portrait mode
        const isPortrait = () => window.matchMedia("(orientation: portrait)").matches;

        // Function to show the popup
        const showPopup = () => {
            if (!isPopupOpenRef.current && isPortrait()) {
                isPopupOpenRef.current = true; // Set the flag to indicate the popup is open
                MySwal.fire({
                    title: '',
                    html: `
                        <div style="display: flex; flex-direction: column; align-items: center;">
                            <img 
                                src="/images/turn_phone.gif" 
                                style="max-width: 80vw; height: auto;" 
                                alt="Book Reader Guide"
                            />
                            <p style="margin-top: 20px; color: white; font-size: 1.2em;">
                                Please rotate your device to landscape mode to continue reading.
                            </p>
                        </div>
                    `,
                    showConfirmButton: true,
                    confirmButtonColor: "#0074D9",
                    confirmButtonText: 'READ BOOK',
                    background: '#f44336', // Changed to a more visible background color
                    customClass: {
                        popup: 'popup-style',
                        title: 'custom-title-class',
                        content: 'custom-content-class',
                        confirmButton: 'confirm-button',
                    },
                    allowOutsideClick: false, // Prevent closing by clicking outside
                    allowEscapeKey: false, // Prevent closing with escape key
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Handle the confirm action if needed
                        // For example, navigate to the book reading page
                    }
                    isPopupOpenRef.current = false; // Reset the flag when popup is closed
                });
            }
        };

        // Function to close the popup
        const closePopup = () => {
            if (isPopupOpenRef.current) {
                MySwal.close();
                isPopupOpenRef.current = false;
            }
        };

        // Initial check: Show popup if in portrait mode
        showPopup();

        // Handler for orientation changes
        const handleOrientationChange = () => {
            if (isPortrait()) {
                showPopup();
            } else {
                closePopup();
            }
        };

        // Add event listeners for orientation and resize changes
        window.addEventListener("orientationchange", handleOrientationChange);
        window.addEventListener("resize", handleOrientationChange); // Fallback for some browsers

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
            window.removeEventListener("resize", handleOrientationChange);
        };
    }, []);

    return null; // This component doesn't render anything visible
};

TurnDevicePopUp.propTypes = {
    // Define prop types if you plan to pass any props in the future
};

export default TurnDevicePopUp;
