import React from 'react'
import Fullpage, {FullPageSections, FullpageSection, FullpageNavigation} from '@ap.cx/react-fullpage'
import {Link} from "react-router-dom";
import Swal from 'sweetalert2';
import ImageWithText from "./reader/ImageWithText";

const BookSlider = ({books}) => {


    const showAlert = () => {
        const html = `
    <div style="display: flex; flex-direction: column; align-items: center;">
    <h2 style={ color: 'red' }>For The Best Reading Experience View In Landscape</h2>
      <img className="" src="https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/landscape_device.png" style="width: 200px; height: auto;" alt={"book reading guide"}/>
    </div>
  `;
        Swal.fire({
            title: '',
            html: html,
            imageAlt: 'Custom Image', // set the alt text of the image
            confirmButtonColor: "#19376D",
            confirmButtonText: 'READ BOOK',
            color: '#19376D',
            background: '#19376D',
            customClass: {
                popup: 'popup-style',
                title: 'custom-title-class',
                content: 'custom-content-class',
                confirmButton: 'confirm-button',
            }
        });
    };


    const SectionStyle = {
        // height: '100vh',
        // width: '100%',
        // display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '40px'
    }

    let myList = [];

    for (let i = 1; i <= books.bookText.length; i++) {
        myList.push(i); // add elements to the array
    }


    showAlert()
    return (
        <div className={"book-reader-div"}>
            <Fullpage>
                <FullpageNavigation className="fp-controlArrow fp-prev"/>

                <FullPageSections>
                    <FullpageSection>
                        {/*<FullpageSection style={{...SectionStyle, backgroundImage: 'url(${bg})', backgroundSize: 'cover'}}>*/}
                        <h1 className={"book-reader-text"}>Scroll Down To Read The First Page</h1>
                    </FullpageSection>
                    {myList.map((number) => (
                        <FullpageSection style={SectionStyle}>
                            <ImageWithText imageUrl={books.pages[number - 1]}
                                           text={books.bookText[number - 1].replace(/%20/g, " ")}
                                           pos={books.pagePosition[number - 1]}/>
                        </FullpageSection>
                    ))}
                    <FullpageSection style={SectionStyle}>
                        <div className="image-container">
                            <img src="https://oststorage.blob.core.windows.net/ostpstrblb/app/end_of_demo.png"
                                 alt="your" width={"100%"}/>
                            <Link to={"/buy-now"}>
                                <button className="col-md-3 col-4 btn btn-lg d-none d-lg-block character-button">
                                    BUY NOW
                                </button>
                                <button className="col-6 btn d-lg-none character-button">
                                    BUY NOW
                                </button>
                            </Link>
                        </div>
                    </FullpageSection>
                </FullPageSections>
            </Fullpage>
        </div>
    );
};

export default BookSlider;