import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { HOME_BUTTON_STYLE } from '../../utils/BookReaderStylesUtils';

const HomeButton = () => (
    <span style={HOME_BUTTON_STYLE}>
        <Link to="/signed-in" className="remove-underlink">
            <Image
                src="https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/home.png"
                style={{ width: '50px', height: '50px', zIndex: '1000' }}
                alt="Home"
            />
        </Link>
    </span>
);

export default HomeButton;
