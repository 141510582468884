import {React, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import "../style/home.css"
import "../style/main.css"
import {usePageViewTracker, useEventTracker} from "../services/TelemetryHooks";
import ScrollToTop from "../components/ScrollToTop";
import {motion, useInView} from "framer-motion";
import MotionButton from "../components/buttons/MotionButton";
import {USER_REVIEWS} from "../utils/ReviewDescriptionsUtils";
import Accordion from "react-bootstrap/Accordion";


const Home = () => {
    usePageViewTracker('Home');
    const trackEvent = useEventTracker();
    const rootImageFolder = "https://oststorage.blob.core.windows.net/ostpstrblb/app/website_feature_images/"
    const howItWorksOneImg = rootImageFolder + "why_square_1.png"
    const howItWorksTwoImg = [rootImageFolder + "why_square_2_1.png", rootImageFolder + "why_square_2_2.png", rootImageFolder + "why_square_2_3.png"];
    const howItWorksThreeImg = rootImageFolder + "why_square_3.png"
    const heartImg = rootImageFolder + "print_book_heart.png"
    const aboutUsImg = rootImageFolder + "about_us_graphic.png"
    const printBookCharacterImg = rootImageFolder + "print_book_character.png"
    const howItWorksStepOneImg = [rootImageFolder + "how_square_1_1.png", rootImageFolder + "how_square_1_2.png", rootImageFolder + "how_square_1_3.png"];
    const howItWorksStepTwoImg = [rootImageFolder + "how_square_2_1.png", rootImageFolder + "how_square_2_2.png", rootImageFolder + "how_square_2_3.png"];
    const howItWorksStepThreeImage = [rootImageFolder + "how_square_3_1.png", rootImageFolder + "how_square_3_2.png", rootImageFolder + "how_square_3_3.png"];
    const ipadPromoImage = [rootImageFolder + "iPad_hero_1.png", rootImageFolder + "iPad_hero_2.png", rootImageFolder + "iPad_hero_3.png", rootImageFolder + "iPad_hero_4.png", rootImageFolder + "iPad_hero_5.png", rootImageFolder + "iPad_hero_6.png"]
    const characterPromoImage = [rootImageFolder + "character_hero_1.png", rootImageFolder + "character_hero_2.png", rootImageFolder + "character_hero_3.png", rootImageFolder + "character_hero_4.png", rootImageFolder + "character_hero_5.png", rootImageFolder + "character_hero_6.png"]
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 767);
    const ref0 = useRef(null);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const isInView0 = useInView(ref0);
    const isInView1 = useInView(ref1);
    const isInView2 = useInView(ref2);
    const isInView3 = useInView(ref3);
    const isInView4 = useInView(ref4);
    const isInView5 = useInView(ref5);
    const isInView6 = useInView(ref6);
    const isInView7 = useInView(ref7);
    const isInView8 = useInView(ref8);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % howItWorksTwoImg.length);
    };

    const [currentIpadImageIndex, setCurrentIpadImageIndex] = useState(0);
    const nextIpadImage = () => {
        setCurrentIpadImageIndex((prevIndex) => (prevIndex + 1) % ipadPromoImage.length);
    };

    const handleClick = (eventName) => {
        trackEvent(eventName);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            nextImage();
            nextIpadImage();
        }, 1200);

        return () => clearInterval(intervalId); // Clean up interval on component unmount
    }, []);


    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 992); // Bootstrap 'lg' is 992px and above
        };

        // Attach the event listener for resize
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className={"form-colour"}>
            <ScrollToTop/>

            {/*Section - Main Landing*/}
            <div className="col-12 landing-container text-center mx-auto ">
                <div className={"col-11 landing-main-text mx-auto"}>
                    <h1 className="poetsen-font text-center mx-auto">
                        {/*We want the text to be 1 line on big screens and 2 on small*/}
                        Personalised {!isLargeScreen && <br/>} Bedtime Stories
                    </h1>
                </div>
                <div>
                    <img
                        className={"mt-2 col-7 col-md-4 col-lg-3 "}
                        src={ipadPromoImage[currentIpadImageIndex]}
                        alt={"create account graphic"}
                    />
                </div>

                <div className={"col-7 col-sm-5 col-md-3 mt-3 mb-3 mx-auto"}>
                    <Link
                        to={"/checkout-trial"}>
                        <MotionButton
                            buttonText={"CREATE A FREE STORY"}
                            // onClick={() => handleClick('Show Now')}
                            buttonClass={"secondary-button"}/>
                    </Link>
                </div>
                <div className={""}>
                    <img
                        className={"col-7 col-md-4 col-lg-2 mx-auto text-center"}
                        src={characterPromoImage[currentIpadImageIndex]}
                        alt={"create account graphic"}
                    />
                </div>
            </div>

            {/*Text Banner - How Does it Work*/
            }
            <div className="feature-div-container banner-text ">
                <h3 className={"text-white"}>Our Story</h3>
            </div>


            <div className="print-container mx-auto text-center">
                <div className={"text-navy-blue text-center mx-auto col-12 "}>
                    <motion.div
                        className={"col-10 mx-auto large-text oswald-font"}
                        ref={ref0}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView0 ? 1 : 0, y: isInView0 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <h1 className={"text-navy-blue"}>The Story Behind Our Storytime</h1>
                        <h3 className={"mt-3"}>
                            We built a platform to help kids fall in love with reading by creating stories with personalised
                            characters who look like them. <br/>
                        </h3>
                        <h3 className={"oswald-font mt-3"}>
                            <strong > Because every child deserves to be the hero of storytime. </strong>
                        </h3>
                    </motion.div>

                    <div>
                        <img src={aboutUsImg}
                             className={"col-12 mt-5"}
                        />
                    </div>

                </div>
            </div>


            {/*Text Banner - How Does it Work*/
            }
            <div className="feature-div-container banner-text ">
                <h3 className={"text-white"}>How It Works?</h3>
            </div>


            {/*Section - How It Works Guide*/
            }
            <div className="multi-item-container">

                <div className={"row col-12 mx-auto text-center mb-5 mt-2"}>

                    {/* Image 1 */}
                    <motion.div
                        className={"col-xl-3 col-11 mx-auto mt-5"}
                        ref={ref1}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView1 ? 1 : 0, y: isInView1 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <img
                            className={"full-width-height-image"}
                            src={howItWorksStepOneImg[currentImageIndex]}
                            alt={"create hardcover book graphic"}
                        />
                    </motion.div>

                    {/* Image 2 */}
                    <motion.div
                        className={"col-xl-3 col-11 mx-auto mt-5"}
                        ref={ref2}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView2 ? 1 : 0, y: isInView2 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <img
                            className={"full-width-height-image"}
                            src={howItWorksStepTwoImg[currentImageIndex]}
                            alt={"create hardcover book graphic"}
                        />
                    </motion.div>

                    {/* Image 3 */}
                    <motion.div
                        className={"col-xl-3 col-11 mx-auto mt-5"}
                        ref={ref3}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView3 ? 1 : 0, y: isInView3 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <img
                            className={"full-width-height-image"}
                            src={howItWorksStepThreeImage[currentImageIndex]}
                            alt={"create hardcover book graphic"}
                        />
                    </motion.div>

                    <div className={"col-8 col-md-4 mt-5 mb-3 mx-auto text-center mb-5"}>
                        <Link to={"/checkout-trial"}
                        >
                            <MotionButton
                                buttonText={"CREATE A FREE STORY"}
                                buttonClass={"secondary-button"}
                                onClick={() => handleClick('Show Now')}
                            />
                        </Link>
                    </div>
                </div>
            </div>

            {/*Text Banner - Our Range Of Stories*/}
            <div className={"feature-div-container banner-text "}>
                <h3 className={"text-white"}>Print Your Personalised Story</h3>
            </div>

            {/*Print Section*/}
            <div className={"col-12 print-container text-center mx-auto"}>
                <div className={"col-10 mx-auto"}>
                    <h1 className="text-navy-blue poetsen-font text-center mx-auto">
                        A Special Gift For <br/> Special Moments
                    </h1>
                    <p className={"mt-3"}>
                        Have your personalised story <br/>
                        <strong> professionally printed </strong>
                    </p>
                </div>

                <motion.div
                    className={"text-center mx-auto"}
                    ref={ref7}
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: isInView7 ? 1 : 0, y: isInView7 ? 0 : 50}}
                    transition={{duration: 0.8}}
                >
                    <img src={heartImg}
                         className={"col-10"}
                    />

                </motion.div>

                <div>
                    <img src={printBookCharacterImg}
                         className={"col-5"}
                    />
                </div>

            </div>


            {/*Text Banner - Our Range Of Stories*/}
            <div className="feature-div-container banner-text ">
                <h3 className={"text-white"}>Reviews</h3>
            </div>

            {/*Product Reviews*/}
            <div className={"infinite-reviews-container pb-5"}
                 style={{overflow: 'hidden', padding: '20px', position: 'relative'}}>
                <h1 className={"col-10 poetsen-font text-center mx-auto text-white"}> 4/5 Parents</h1>
                <h5 className={"col-11 poetsen-font text-center mx-auto text-white"}> Would Recommend Us To A
                    Friend*</h5>

                <div
                    className={"reviews-wrapper mb-5"}
                    style={{
                        display: 'flex',
                        gap: '20px', // Space between items
                        padding: '10px', // Extra padding around the container
                    }}
                >

                    {[...USER_REVIEWS, ...USER_REVIEWS, ...USER_REVIEWS, ...USER_REVIEWS].map((review, index) => (
                        <div className={"bg-white mt-3"}
                             key={index}
                             style={{
                                 width: '250px',
                                 height: '300px',
                                 flexShrink: 0,
                                 backgroundColor: '#f5f5f5',
                                 borderRadius: '10px',
                                 padding: '20px',
                                 display: 'flex',
                                 flexDirection: 'column',
                                 alignItems: 'center',
                                 // justifyContent: 'center',
                                 // border: '5px solid #091E3B',  // Add a navy border with 2px width

                             }}
                        >
                            <img
                                src={review.image}
                                alt="Reviewer"
                                style={{width: '100px', height: '100px', borderRadius: '10%', marginBottom: '10px'}}
                            />
                            {/*<div>{'⭐'.repeat(review.rating)}</div>*/}
                            <p className={"text-center col-10 "}>{review.text}</p>
                            <p className={"agbalumo-font"}>{review.name}</p>
                        </div>

                    ))}

                </div>
            </div>


            {/*Text Banner - How Does it Work*/
            }
            <div className="feature-div-container banner-text ">
                <h3 className={"text-white"}>More Than A Story</h3>
            </div>


            {/*Section - How It Works Guide*/
            }
            <div className="multi-item-container">
                <div className={"row col-12 mx-auto text-center mb-5"}>


                    {/* Image 1 */}
                    <motion.div
                        className={"col-xl-3 col-11 mx-auto mt-5"}
                        ref={ref4}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView4 ? 1 : 0, y: isInView4 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <img
                            className={"full-width-height-image"}
                            src={howItWorksOneImg}
                            alt={"create account graphic"}
                        />
                    </motion.div>

                    {/* Image 2 */}
                    <motion.div
                        className={"col-xl-3 col-11 mx-auto mt-5"}
                        ref={ref5}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView5 ? 1 : 0, y: isInView5 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <img
                            className={"full-width-height-image"}
                            src={howItWorksTwoImg[currentImageIndex]}
                            alt={"create hardcover book graphic"}
                        />
                    </motion.div>

                    {/* Image 3 */}
                    <motion.div
                        className={"col-xl-3 col-11 mx-auto mt-5"}
                        ref={ref6}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView6 ? 1 : 0, y: isInView6 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <img
                            className={"full-width-height-image"}
                            src={howItWorksThreeImg}
                            alt={"generate personal library graphic"}
                        />
                    </motion.div>

                    <div className={"col-8 col-md-4 mt-5 mb-3 mx-auto text-center "}>
                        <Link to={"/checkout-trial"}
                        >
                            <MotionButton
                                buttonText={"CREATE A FREE STORY"}
                                buttonClass={"secondary-button"}
                                onClick={() => handleClick('Show Now')}
                            />
                        </Link>
                    </div>
                </div>
            </div>


            {/*Text Banner - How Does it Work*/
            }
            <div className="feature-div-container banner-text ">
                <h3 className={"text-white"}>Frequently Asked Questions</h3>
            </div>

            {/*Section - FAQ*/
            }
            <div className={"col-12 print-container mx-auto text-center"}>

                <Accordion defaultActiveKey="0" flush className={"custom-accordion rounded-5 p-5"}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="custom-accordion rounded-5 ">1. Is the Our Storytime Online Web Platform
                            Free?</Accordion.Header>
                        <Accordion.Body>
                            Getting started on our online web platform is free. Your personalised digital stories can be
                            accessed on web browsers across mobile, tablet and desktop devices so you can read whether
                            at home or on the go.
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="1">
                        <Accordion.Header className="custom-accordion">2. Can I order a hard copy?</Accordion.Header>
                        <Accordion.Body>
                            Yes you can! Once you login to your library you'll have access to your personal bookshop
                            where you can purchase physical copies and have your stories professionally printed and
                            delivered.
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>3. How old does my child need to be?</Accordion.Header>
                        <Accordion.Body>
                            Our Storytime books are designed for children aged 2 - 8. But that doesn’t mean older
                            children have to miss out on the fun! </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="3">
                        <Accordion.Header>4. How do I contact Our Storytime?</Accordion.Header>
                        <Accordion.Body>
                            The best way to reach us is via email at sales@ourstorytime.com.
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                        <Accordion.Header>5. How often does Our Storytime release books?</Accordion.Header>
                        <Accordion.Body>
                            We typically release 1-2 new stories at a time. </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="5">
                        <Accordion.Header>6. My child doesn’t fit within the customisation options?</Accordion.Header>
                        <Accordion.Body>
                            We are always working on providing new customisation options, if you don’t see a suitable
                            option for your child feel free to email suggestions to sales@ourstorytime.com and we’ll do
                            our very best to accommodate in future releases. In the meantime, feel free to enjoy our
                            current catalogue of options and let their imagination run wild. </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            </div>


        </div>
    );
}
export default Home;
