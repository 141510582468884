import React, {useState, useEffect} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style/cardslider.css'
import {loadStripe} from "@stripe/stripe-js";
import { toast } from 'react-toastify';
import {UserAuth} from "../components/AuthContext";
import Signup from "../components/Signup";
import CharacterSelector from "../components/character/CharacterSelector";
import CustomBookMessage from "../components/CustomBookMessage";
import HardCoverBookSelector from "../components/HardCoverBookSelector";


document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';
const schoolBookImage = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/display_school_hard_cover.png";
const birthdayBookImage = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/display_birthday_hard_cover.png";
const zooBookImage = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/display_zoo_hard_cover.png";

const CheckoutHardCoverPass = () => {
    const [bookOneSelected, setBookOneSelected] = useState("first-day");
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('');
    const [reEnteredPassword, setReEnteredPassword] = useState('');
    const {createUser, signIn} = UserAuth();
    const [buttonOneIsSelected, setButtonOneIsSelected] = useState(true);
    const [buttonTwoIsSelected, setButtonTwoIsSelected] = useState(false);
    const [buttonThreeIsSelected, setButtonThreeIsSelected] = useState(false);
    const [stripeError, setStripeError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const priceLink = process.env.REACT_APP_HARD_COVER;
    const [hairColour, setHairColour] = useState("black");
    const [skinTone, setSkinTone] = useState("fair");
    const [hairStyle, setHairStyle] = useState("buzzhair");
    const [characterName, setCharacterName] = useState("");
    const [bookMessage, setBookMessage] = useState('');
    const [selectedBookImage, setSelectedBookImage] = useState(schoolBookImage);
    const [authErrorMessage, setAuthErrorMessage] = useState("")
    const [characterNameErrorMessage, setCharacterNameErrorMessage] = useState("")
    const [bookMessageErrorMessage, setBookMessageErrorMessage] = useState("")
    const [usernameErrorMessage, setUsernameErrorMessage] = useState("")
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("")

    //character selector
    const myUser = {
        "email": email,
        "firstName": firstName,
        "lastName": lastName,
        "bookSelected": bookOneSelected,
        "characterName": characterName,
        "skinTone": skinTone,
        "hairStyle": hairStyle,
        "hairColour": hairColour,
        "bookMessage": bookMessage
    }

    const postNewUser = async () => {
        try {
            const url = process.env.REACT_APP_B_KEND + '/osthardcoveruser';
            await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(myUser),
            }).then(response => response.json())
                .then(data => data);
        } catch (error) {
            toast.error(error.message)

        }
    };

    let stripePromise
    const getStripe = () => {
        if (!stripePromise) {
            stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
        }
        return stripePromise;
    }

    const item = {
        price: priceLink,
        quantity: 1
    };

    const checkoutOptions = {
        lineItems: [item],
        mode: "subscription",
        billingAddressCollection: "required",

        // mode: "subscription",
        successUrl: `${window.location.origin}/post-sale-hardcover-signed-in?sessionid=${priceLink}`,
        cancelUrl: `${window.location.origin}/checkout-hard-cover-pass`
    };
    
    const redirectToCheckout = async () => {
        const stripe = await getStripe();
        const {error} = await stripe.redirectToCheckout(checkoutOptions);
        if (error) setStripeError(error.message);
        if (stripeError) alert(stripeError);
        setIsLoading(false);
    }

    const validateCharacterName = () => {
        if (characterName.length > 2) {
            setCharacterNameErrorMessage("");
            return true;
        } else {
            setCharacterNameErrorMessage("* Please Enter Your Custom Character's Name");
            toast.error("Please Enter Your Custom Character's Name");
            setIsLoading(false)
            return false;
        }
    }

    const validateCustomMessage = () => {
        if (bookMessage.length > 10 &&
            bookMessage.length < 150) {
            setBookMessageErrorMessage("");
            return true;

        } else {
            setBookMessageErrorMessage("* Please Ensure Your Custom Message Is Between 10 & 150 Characters");
            toast.error("Please Ensure Your Custom Message Is Between 10 & 150 Characters");
            setIsLoading(false)
            return false;
        }
    }

    const validateLoginDetails = () => {
        if (firstName.length > 1 &&
            lastName.length > 1) {
            setUsernameErrorMessage("");
            return true;
        } else {
            setUsernameErrorMessage("* Please Ensure Your First & Last Name Are At Least 2 Letters");
            toast.error("Please Ensure Your First & Last Name Are At Least 2 Letters");
            setIsLoading(false)
            return false;
        }
    }

    const validatePasswordMatches = () => {
        if (password === reEnteredPassword &&
            password.length > 7
            && reEnteredPassword.length > 7) {
            setPasswordErrorMessage("");
            return true;
        } else {
            setPasswordErrorMessage("* Please Ensure Your Password's Match & Are Longer Than 7 Characters");
            toast.error("Please Ensure Your Password's Match & Are Longer Than 7 Characters");
            setIsLoading(false)
            return false;
        }
    }

    const handleBookOneClicked = () => {
        setBookOneSelected("first-day");
    };

    const handleBookTwoClicked = () => {
        setBookOneSelected("birthday");
    };

    const handleBookThreeClicked = () => {
        setBookOneSelected("zoo");
    };

    const handleBookOneChange = (event) => {
        setBookOneSelected(event.target.value)
    };


    useEffect(() => {
        if (bookOneSelected === "first-day") {
            setButtonOneIsSelected(true);
            setButtonTwoIsSelected(false);
            setButtonThreeIsSelected(false);
            setSelectedBookImage(schoolBookImage);
        } else if (bookOneSelected === "birthday") {
            setButtonOneIsSelected(false);
            setButtonTwoIsSelected(true);
            setButtonThreeIsSelected(false);
            setSelectedBookImage(birthdayBookImage);

        } else {
            setButtonOneIsSelected(false);
            setButtonTwoIsSelected(false);
            setButtonThreeIsSelected(true);
            setSelectedBookImage(zooBookImage);
        }
    })
    
    const onLogin = async (e) => {
        e.preventDefault();
        try {
            await signIn(email, password);
        } catch (e) {
            toast.error(e.message)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true);
        console.log(bookMessage)
        
        if (password === reEnteredPassword &&
            validateCharacterName()
            && validateCustomMessage()
            && validateLoginDetails()
            && validatePasswordMatches()) {

            try {
                toast.info("Creating OST User")
                await createUser(email, password)
                postNewUser();
                onLogin();
                await redirectToCheckout();
            } catch (e) {
                const errorMessage = e.message;
                const errorDisplayMessage = errorMessage.toString().replace("Firebase: ", "");
                toast.error(errorDisplayMessage);
                setAuthErrorMessage(errorDisplayMessage)
                setIsLoading(false);
            }
        }
    }
    
    return (
        <div className="mx-auto align-items-center">
            <h1 className={"text-center"}>Checkout</h1>
            <div className="col-md-10 col-11 mx-auto card bg-white border-white">

                {/* This is the select a book*/}
                <div className={"mx-auto align-items-center justify-content-center mb-2"}>
                    <div className={"mx-auto align-items-center justify-content-center"}>
                        <HardCoverBookSelector
                            schoolBookImage={schoolBookImage}
                            birthdayBookImage={birthdayBookImage}
                            zooBookImage={zooBookImage}
                            bookOneSelected={bookOneSelected}
                            setBookOneSelected={setBookOneSelected}
                            buttonOneIsSelected={buttonOneIsSelected}
                            handleBookOneClicked={handleBookOneClicked}
                            handleBookOneChange={handleBookOneChange}
                            buttonTwoIsSelected={buttonTwoIsSelected}
                            handleBookTwoClicked={handleBookTwoClicked}
                            buttonThreeIsSelected={buttonThreeIsSelected}
                            handleBookThreeClicked={handleBookThreeClicked}
                            headerMessage={"1. Select Your Story"}
                        />
                    </div>
                </div>

                {/*Select Character*/}
                <div className={"mx-auto align-items-center justify-content-center"}>
                    <div className={"mx-auto align-items-center justify-content-center"}>
                        <CharacterSelector
                            hairColour={hairColour}
                            setHairColour={setHairColour}
                            skinTone={skinTone}
                            setSkinTone={setSkinTone}
                            hairStyle={hairStyle}
                            setHairStyle={setHairStyle}
                            characterName={characterName}
                            setCharacterName={setCharacterName}
                            headerMessage={"2. Character Selector"}
                            textColour={'text-navy'}
                            // handleSubmit={handleSubmit}
                        />
                    </div>
                </div>

                {/*Custom Book Message*/}
                <div className={"mx-auto align-items-center justify-content-center"}>
                    <div className={"mx-auto align-items-center justify-content-center"}>
                        <CustomBookMessage
                            bookMessage={bookMessage}
                            setBookMessage={setBookMessage}
                            selectedBookImage={selectedBookImage}
                            headerMessage={"3. Custom Message"}
                        />
                    </div>
                </div>


                {/*Sign In*/}
                <div className={"mx-auto align-items-center justify-content-center"}>
                    <div className={"mx-auto align-items-center justify-content-center"}>
                        {
                            <Signup email={email}
                                    setEmail={setEmail}
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    password={password}
                                    setPassword={setPassword}
                                    reEnteredPassword={reEnteredPassword}
                                    setReEnteredPassword={setReEnteredPassword}
                                    headerMessage={"4. Sign Up"}
                            />
                        }
                    </div>
                </div>
                <div><p className={"text-danger text-center"}>{characterNameErrorMessage}</p></div>
                <div><p className={"text-danger text-center"}>{bookMessageErrorMessage}</p></div>
                <div><p className={"text-danger text-center"}>{usernameErrorMessage}</p></div>
                <div><p className={"text-danger text-center"}>{passwordErrorMessage}</p></div>
                <div><p className={"text-danger text-center"}>{authErrorMessage}</p></div>

                <div className={"col-12 negative-20-margin-top mx-auto text-center"}>
                    {
                        isLoading === true ?
                            (
                                <button className="col-6 mt-1 oswald-font btn btn-lg btn-warning text-bold rounded-3  rounded-pill mb-3" onClick={onSubmit}>
                                    LOADING ...
                                </button>
                            )
                            :
                            (
                                <button className="col-6 mt-1 oswald-font btn btn-lg btn-primary text-bold rounded-3  rounded-pill mb-3" onClick={onSubmit}>
                                    CHECKOUT
                                </button>
                            )
                    }
                </div>
            </div>
        </div>);
};
export default CheckoutHardCoverPass;