import React from 'react';
import PropTypes from 'prop-types';

const CharacterTrait = ({
  options,
  selectedValue,
  onChange,
  groupName,
  groupLabel,
  imageBaseUrl,
  textColour,
    colWidth,
}) => (
  <fieldset className="col-11 mx-auto">
    <legend className={`login-header ${textColour}`}>{groupLabel}</legend>
    <div className="col-12 mx-auto justify-content-between" role="radiogroup" aria-labelledby={`${groupName}-group`}>
      {options.map((option) => (
        <label key={option.value} className={`${colWidth}`} htmlFor={`${groupName}-${option.value}`}>
          <img
            className="select-image"
            src={`${imageBaseUrl}${option.img}`}
            alt={option.label}
          />
          <input
            id={`${groupName}-${option.value}`}
            type="radio"
            name={groupName}
            value={option.value}
            className="input-hidden mt-1"
            checked={selectedValue === option.value}
            onChange={() => onChange(option.value)}
            aria-checked={selectedValue === option.value}
            aria-label={option.label}
          />
        </label>
      ))}
    </div>
  </fieldset>
);

CharacterTrait.propTypes = {
  options: PropTypes.array.isRequired,
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
  groupLabel: PropTypes.string.isRequired,
  imageBaseUrl: PropTypes.string.isRequired,
  textColour: PropTypes.string,
};

CharacterTrait.defaultProps = {
  textColour: '',
};

export default CharacterTrait;
