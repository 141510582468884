export default class ConsoleTelemetryProvider {
    trackEvent(event, properties) {
      // console.log(`Event: ${event}`, properties);
    }
  
    trackPageView(name, url) {
      // console.log(`Page View: ${name}`, { url });
    }
  
    trackException(exception) {
      console.error(`Exception:`, exception);
    }
  
    trackMetric(name, value) {
      console.log(`Metric: ${name}`, { average: value });
    }
  
    setUserContext(userId) {
      console.log(`User Context: ${userId}`);
    }
  }
  