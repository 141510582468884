import { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup,
    getAdditionalUserInfo,
    signInWithRedirect,
    getRedirectResult
} from "firebase/auth";
import apiService from "../services/apiService";
import { auth } from "./firebase";
import * as Sentry from '@sentry/react';

const UserContext = createContext();

const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const createUser = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    };

    const logout = () => {

        Sentry.setUser(null);

        return signOut(auth);
    };

    const setupSentryUser = (email) => {
        Sentry.setUser({
            id: email,
            username: email,
            email: email
          });
    }

    const signIn = (email, password) => {
        setupSentryUser(email);
        return signInWithEmailAndPassword(auth, email, password);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                // Get the user's sign-in method
                const result = await getRedirectResult(auth);
                if (result) {
                    const newUser = getAdditionalUserInfo(result).isNewUser;
                    const user = result.user;
                    setUser(user);

                    if (newUser) {
                        await registerInternalUserWithSso(user);
                    }
                    setupSentryUser(user.email);
                } else {
                    // This block executes if the user is already signed in and not coming from a redirect
                    setUser(user);  // Update user state
                    console.log('User is already signed in or not coming from a redirect');
                }
            }
        });

        // Cleanup the listener when the component unmounts
        return () => unsubscribe();
    }, []);



    const registerInternalUserWithSso = async (user) => {
        const name = user.displayName;
        try {
            let character = apiService.getDefaultCharacterSettings();

            character.email = user.email;
            character.firstName = name;
            character.lastName = name;
            character.characterName = name;

            return await apiService.updateUsersCharacter(character);
        } catch (error) {
            console.error("Error creating user:", error);
            throw error;
        }
    };

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        const useRedirect = false; // isMobileDevice();
        try {
            if (useRedirect) {
                await signInWithRedirect(auth, provider);
            } else {

                const result = await signInWithPopup(auth, provider);
                const newUser = getAdditionalUserInfo(result).isNewUser;
                const user = result.user;
                setUser(user);

                if (newUser) {
                    await registerInternalUserWithSso(user);
                }
                setupSentryUser(user.email);
                return user;
            }
        } catch (error) {
            console.error("Error during Google sign-in:", error);
            throw error;
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
        return unsubscribe;
    }, []);

    return (
        <UserContext.Provider value={{ createUser, user, logout, signIn, signInWithGoogle }}>
            {children}
        </UserContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(UserContext);
};

