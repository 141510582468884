import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setupHotjar, setupSentry, setupTelemetry, setupGoogleTagManager } from './setup';
import { TelemetryContext } from './services/TelemetryService';


if (process.env.REACT_APP_HOTJAR_SITE_ID) {
  setupHotjar();
}

if (process.env.REACT_APP_SENTRY_DSN) {
  setupSentry();
}

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
  setupGoogleTagManager(process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID);
}

const telemetryService = setupTelemetry();
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);



root.render(
  <TelemetryContext.Provider value={telemetryService}>
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>
  </TelemetryContext.Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
